// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$portefolio-primary: mat.define-palette(mat.$indigo-palette);
$portefolio-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$portefolio-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$portefolio-theme: mat.define-light-theme((
  color: (
    primary: $portefolio-primary,
    accent: $portefolio-accent,
    warn: $portefolio-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($portefolio-theme);


@import "~bootstrap/scss/bootstrap";

// Fonts
@import 'app/utils/sass/_fonts';

// Variables
@import "app/utils/sass/_mixins";
@import "app/utils/sass/_variables";
@import "app/utils/sass/sizes";

//Extends
@import "app/utils/sass/extends";

body,
html {
  @include font-spacemono-regular();
  overflow-x: hidden;
  background-color: #2D2E32;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
