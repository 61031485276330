$font-path: "/fonts/";

@mixin registerFont(
    $classname,
    $name,
    $folder,
    $font,
    $weight,
    $style: normal
) {
    $fontpath: "#{$font-path}#{$folder}/#{$font}";
    @font-face {
        font-family: "#{$name}";
        src: url("#{$fontpath}.ttf") format("truetype");
        font-weight: $weight;
        font-style: $style;
        font-display: swap;
    }
    .#{$classname} {
        font-family: $name;
        font-weight: $weight;
        font-style: $style;
    }
}


@include registerFont('spacemono-regular', 'spacemono', 'SpaceMono', 'SpaceMono-Regular', 400);
@include registerFont('spacemono-italic', 'spacemono', 'SpaceMono', 'SpaceMono-Italic', 400, italic);
@include registerFont('spacemono-bold', 'spacemono', 'SpaceMono', 'SpaceMono-Bold', 700);
@include registerFont('spacemono-bolditalic', 'spacemono', 'SpaceMono', 'SpaceMono-Bolditalic', 700, italic);


@mixin font-spacemono-regular() {
    @extend .spacemono-regular;
}
@mixin font-spacemono-italic() {
    @extend .spacemono-italic;
}

@mixin font-spacemono-bold() {
    @extend .spacemono-bold;
}

@mixin font-spacemono-bolditalic() {
    @extend .spacemono-bolditalic;
}



